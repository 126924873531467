<template>
  <div class="row mt-2">
    <div class="col-lg-8 ">
      <b-card
        v-if="job"
        header-tag="header"
        style="max-width: 100%;"
      >
        <template #header>
          <div class="pb-1 w-100 divider-bottom d-flex flex-column flex-sm-row justify-content-between align-items-center m-0 p-0">
            <h1 class="m-0 text-primary font-weight-bolder w-75">
              {{ job.title }}
            </h1>
            <h5 class="m-0 line-height-1 text-right">
              {{ $t('Deadline') }}: {{ job.application_deadline | transformDate }}
            </h5>
          </div>
        </template>
        <h5 v-if="workplace && workplace.name" class="font-weight-bolder">
          {{ workplace.name }}
        </h5>
        <img
          v-if="workplace && workplace.image"
          class="mb-2 mt-1 job-logo"
          :src="workplace.image"
          alt=""
        >
        <div
          v-if="job"
          class="d-flex align-items-center mb-8"
        >
          <feather-icon
            icon="ClockIcon"
            size="13"
            style="color:#2986E8"
            class="mr-10"
          />
          <span class="mr-10 mb-0 h5 text-muted ">{{ $t('Job type') }}:</span>
          <span class="h5 mb-0 ">{{ types[job.type] }}</span>
        </div>
        <div class="d-flex align-items-center mb-8">
          <feather-icon
            icon="StarIcon"
            size="13"
            style="color:#2986E8"
            class="mr-10"
          />
          <span class="mr-10 mb-0 h5 text-muted ">{{ $t('Category') }}:</span>
          <span
            v-for="(category, index) in translate(job.job_categories, $i18n.locale)"
            :key="category.id"
            class="h5 mb-0 "
          >{{ category.name }}<span v-if="index !== job.job_categories.length - 1">,&nbsp;</span></span>
        </div>
        <div class="d-flex align-items-center mb-8">
          <feather-icon
            icon="BookmarkIcon"
            size="13"
            style="color:#2986E8"
            class="mr-10"
          />
          <span class="mr-10 mb-0 h5 text-muted ">{{ $t('Specialty') }}:</span>
          <div class="d-flex flex-wrap p-0">
            <h6
              v-for="specialty in translate(job.specialties, $i18n.locale)"
              :key="specialty.id"
              class="badge-custom d-flex align-items-center mb-0"
            >
              <span /> {{ specialty.name }}
            </h6>
          </div>
        </div>
        <div class="d-flex align-items-center mb-8">
          <feather-icon
            icon="PieChartIcon"
            size="13"
            style="color:#2986E8"
            class="mr-10"
          />
          <span class="mr-10 mb-0 h5 text-muted ">{{ $t('Position type') }}:</span>
          <span class="h5 mb-0 ">{{ job.rate }}%</span>
        </div>
        <div class="d-flex align-items-center mb-8">
          <feather-icon
            icon="MapPinIcon"
            size="13"
            style="color:#2986E8"
            class="mr-10"
          />
          <span class="mr-10 mb-0 h5 text-muted ">{{ $t('Address') }}:</span>
          <span class="h5 mb-0 ">
            <div v-if="job.work_address_1">
              {{ job.work_address_1 }}
            </div>
          </span>
        </div>
        <div class="mt-1">
          <h5 class="font-weight-bolder">
            {{ $t('Description') }}
          </h5>
          <!-- eslint-disable-next-line -->
          <h5 v-html="job.description" />
        </div>
      </b-card>
    </div>
    <div
      v-if="job"
      class="col-lg-4 pl-md-1"
    >
      <b-card
        v-if="job.recruiting_system_link"
        style="max-width: 100%;"
        body-class="d-flex flex-column align-items-start"
      >
        <h3 class="font-weight-bolder">
          {{ $t('Apply for this job') }}
        </h3>
        <a
          class="h5 text-primary btn btn-outline-primary"
          @click="markAsViewed"
        >{{ $t('Apply here') }}</a>
      </b-card>
      <b-card
        style="max-width: 100%;"
      >
        <h3 class="font-weight-bolder">
          {{ $t('Contact person') }}
        </h3>
        <div class="d-flex">
          <b-avatar
            variant="secondary"
            class="mr-1"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="UserIcon"
                size="20"
                style="color:#2986E8"
              />
            </span>
          </b-avatar>
          <div>
            <h5 class="m-0">
              {{ job.contact_person }}
            </h5>
            <h5 class="text-primary">
              {{ job.contact_phone_number }}
            </h5>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import { BCard, BAvatar } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BAvatar,
  },
  filters: {
    transformDate(deadline) {
      return moment(deadline).format('MM/DD/YYYY')
    },
  },
  data() {
    return {
      types: {
        full_time: this.$t('Full time'),
        substitude: this.$t('Substitude'),
      },
    }
  },
  computed: {
    job() {
      return this.$store.getters[`${this.isOwnJob ? 'myJobs' : 'jobs'}/selectedJob`]
    },
    specialties() {
      return this.$store.getters['specialties/specialties']
    },
    workplaces() {
      return this.translate(this.$store.getters['workplaces/workplaces'], this.$i18n.locale)
    },
    isOwnJob() {
      return this.$route.name === 'my_jobs'
    },
    workplace() {
      if (this.job.workplace_situation_group === 'general_practice') {
        return this.job.workplace_situation.clinic
      }
      if (this.job.workplace_situation_group === 'hospital') {
        let workplace = null
        const keys = Object.entries(this.job.workplace_situation)
        keys.forEach(([key]) => {
          workplace = this.job.workplace_situation[key]
        })
        return workplace
      }
      if (this.job.workplace_situation_group === 'other') {
        return this.job.workplace_situation.custom_workplace
      } return null
    },
  },
  created() {
    if (this.isOwnJob) {
      this.$store.dispatch('myJobs/fetchJobById', this.$route.params.id)
    } else { this.$store.dispatch('jobs/fetchJobById', this.$route.params.id) }
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('workplaces/fetchWorkplaces')
  },
  methods: {
    markAsViewed() {
      this.$store.dispatch('jobs/markAsViewed', this.$route.params.id)
      window.open((this.job.recruiting_system_link.includes('http') ? '' : '//') + this.job.recruiting_system_link, '_blank')
    },
  },
}
</script>
